<template>
    <div :style="wrapperStyle">
        <div v-if="showLoader" class="d-flex flex-column align-center justify-center text-center" style="height: 100%">
            <div>
                <p>Application is launching powered by:</p>
                <div>
                    <span class="caption">
                        <span v-if="resourceLimit.cpu !== 0 && deployment.node_pool === 'nv-app'">
                            {{ resourceLimit.cpu }} CU = {{ resourceLimit.cpu }} vCPU / {{ resourceLimit.mem }} GB memory
                        </span>
                        <div class="secondary--text font-weight-bold" v-if="deployment.node_pool !== 'nv-app'">
                            Procuring your computing resources. ETA 5-10 minutes.
                        </div>
                    </span>
                </div>
                <v-progress-linear indeterminate color="primary" rounded height="6" class="mt-5 mb-1"></v-progress-linear>
            </div>
            <v-stepper :value="loadStatus">
                <v-stepper-header>
                    <v-stepper-step :complete="loadStatus > 1" step="1">Allocating</v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="loadStatus > 2" step="2">Configuring</v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="3">Booting</v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">
                        <span v-if="deployment.node_pool !== 'nv-app'">Allocating computing resources is underway.</span>
                        <span v-else>Allocating computing resources is underway. Due to high demand, it will take a bit longer.</span>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        Finalizing software configuration.
                        <span v-if="deployment.node_pool !== 'nv-app'">Due to high demand, it will take a bit longer.</span>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        Booting your application.
                        <br />
                        <v-icon color="success" large class="mt-2">done</v-icon>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
            <div style="width: 500" v-if="!appLongWait">
                <v-carousel height="200" hide-delimiters :show-arrows="false" style="margin-bottom: -200px" :cycle="true">
                    <v-carousel-item v-if="deployment.app_type === appTypes.RSTUDIO.name">
                        <v-alert class="mt-3" colored-border color="blue" border="left" max-width="500" light>
                            Hint: for heavier / long running computations in RStudio,
                            <a
                                href="https://docs.nuvolos.cloud/user-guides/research-guides/high-performance-computing#rstudio"
                                style="text-decoration: none"
                                target="_blank">
                                we recommend RStudio jobs
                            </a>
                            .
                        </v-alert>
                    </v-carousel-item>
                    <v-carousel-item v-if="deployment.app_type === appTypes.JUPYTER.name">
                        <v-alert class="mt-3" colored-border color="blue" border="left" max-width="500" light>
                            Hint: you can add additional kernels/environments (e.g. R, Python) to JupyterLab by,
                            <a
                                href="https://docs.nuvolos.cloud/user-guides/research-guides/high-performance-computing#jupyterlab"
                                style="text-decoration: none"
                                target="_blank">
                                following our guide
                            </a>
                            .
                        </v-alert>
                    </v-carousel-item>
                    <v-carousel-item v-if="isGuac && loadStatus < 3">
                        <v-alert class="mt-3" colored-border color="blue" border="left" max-width="500" light>
                            Hint: You can switch windows inside the application using SHIFT + TAB
                        </v-alert>
                    </v-carousel-item>
                    <v-carousel-item v-if="showDropboxHint && loadStatus < 3">
                        <v-alert class="mt-3" colored-border color="blue" border="left" max-width="500" light>
                            <div class="d-flex align-center">
                                <div>
                                    Hint: You can
                                    <a
                                        style="text-decoration: none"
                                        href="https://docs.nuvolos.cloud/features/file-system-and-storage/synchronize-with-dropbox"
                                        target="_blank">
                                        sync your files
                                    </a>
                                    on Nuvolos with
                                </div>
                                <v-img class="ml-2" src="@/assets/Dropbox.svg" max-width="100" />
                            </div>
                        </v-alert>
                    </v-carousel-item>
                </v-carousel>
            </div>
            <div class="caption mt-4 grey--text" v-if="appLongWait && deployment.node_pool === 'nv-app'">
                If your application doesn't load in a few minutes, please try
                <br />
                <v-btn @click="restartApp" text color="primary" class="text-lowercase" small>
                    <v-icon small class="mr-1">power_settings_new</v-icon>
                    restarting
                </v-btn>
                <div class="mt-2">or</div>
                <div class="mt-2">
                    <v-btn
                        @click="showNewMessageIntercom('Hi Nuvolos Team, \nI would like to ask your help regarding the following:\n', userInfo)"
                        text
                        color="primary"
                        class="text-lowercase"
                        small>
                        <v-icon small class="mr-1">support</v-icon>
                        ask for help
                    </v-btn>
                </div>
            </div>
        </div>
        <iframe
            :src="proxy_url"
            :style="iframeStyle"
            @load="hideLoader"
            :id="deployment.session_id"
            @mouseenter="focusIframe"
            allow="clipboard-read; clipboard-write"
            v-if="proxy_url" />
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { dropboxEnums } from '@/mixins/dropbox'
import { enumsData } from '@/mixins/enums'
import { appTypeAndImageLink } from '@/mixins/appTypeAndImage'
import { intercomMethods } from '@/mixins/intercom'

export default {
    mixins: [appTypeAndImageLink, intercomMethods, enumsData, dropboxEnums],
    props: {
        deployment: Object,
        isActive: Boolean,
        appType: String
    },
    data() {
        return {
            currentSessionId: '',
            cookieTimer: null,
            cookieVal: null,
            showLoader: true,
            aid: this.deployment.aid,
            isChromiumBased: window.chrome,
            appLongWait: false,
            longAppLoad: null,
            clearURL: false,
            showedClipboardWarnig: false,
            waitingForCookie: true
        }
    },
    methods: {
        getCookie() {
            const proxyURL = 'https://' + this.deployment.domain
            return this.$axios
                .get(`${proxyURL}/nuvolosproxy/${this.deployment.aid}/get_cookie?session_id=${this.deployment.session_id}`, {
                    withCredentials: true
                })
                .then(response => {
                    this.waitingForCookie = false
                })
                .catch(error => {
                    console.log(error)
                })
        },
        pushNotification() {
            if (!('Notification' in window)) return
            if (!document.hidden) return

            if (Notification.permission === 'granted') {
                this.showNotification()
            } else if (Notification.permission !== 'denied') {
                Notification.requestPermission().then(permission => {
                    if (permission === 'granted') {
                        this.showNotification()
                    }
                })
            }
        },
        showNotification() {
            const notification = new Notification(this.deployment.name, {
                body: 'Your Nuvolos app has started!',
                icon: this.deployment.icon_url
            })
            notification.onclick = function () {
                window.focus()
            }
        },
        hideLoader(event) {
            if (this.proxy_url) {
                this.$axios.post(`/applications/${this.deployment.aid}/app_loaded`).catch(error => {
                    console.log(error)
                })
                window.setTimeout(() => {
                    this.showLoader = false
                    this.$nextTick(() => {
                        this.focusIframe()
                        this.pushNotification()
                    })
                    if (this.isChromiumBased && this.isGuac) {
                        navigator.clipboard
                            .readText()
                            .then(clipText => {
                                navigator.clipboard.writeText(clipText).catch(err => {
                                    this.showErrModal(err)
                                })
                            })
                            .catch(err => {
                                this.showErrModal(err)
                            })
                            .finally(() => {
                                this.focusIframe()
                            })
                    }
                }, 1000)
            }
        },
        restartApp() {
            this.$store.dispatch('appStore/stopApp', { aid: this.deployment.aid, sessionId: this.deployment.session_id }).then(() => {
                location.reload()
            })
        },
        focusIframe() {
            if (this.$route.name === 'app-open' && !this.showCredentialModal) {
                window.scrollTo(0, 0)
                document.getElementsByTagName('main')[0].scrollTop = 0
                if (document.getElementById(this.deployment.session_id)) {
                    if (this.isGuac) {
                        document.getElementById(this.deployment.session_id).blur()
                        document.getElementById(this.deployment.session_id).focus()
                    }
                }
            }
        },
        showErrModal(err) {
            console.log(err)
            if (document.hasFocus()) {
                this.$store.dispatch('showGlobalDialog', {
                    dialogTitle: 'Enable clipboard access',
                    dialogText: 'Please enable clipboard access for Nuvolos, otherwise copy-paste will not work properly',
                    dialogAction: ['dismiss']
                })
            }
        }
    },
    mounted() {
        this.getCookie()
        this.focusIframe()
        this.cookieTimer = setInterval(() => {
            if (document.hasFocus() && this.$auth.isAuthenticated && this.isActive) {
                this.getCookie()
            }
        }, 1 * 60000)

        window.addEventListener('focus', () => {
            this.focusIframe()
        })

        this.appLongWait = false
        this.longAppLoad = setTimeout(() => {
            this.appLongWait = true
        }, 300000)
    },
    beforeDestroy() {
        clearInterval(this.cookieTimer)
        this.clearURL = true
    },
    computed: {
        showDropboxHint() {
            if ((this.isMasterInstance || this.currentSpaceType === this.spaceTypes.RESEARCH_SPACE) && !this.isDropboxLinked) {
                return true
            }
            return false
        },
        proxy_url() {
            if (this.clearURL || this.waitingForCookie || this.deployment.available_replicas < 1) {
                return ''
            }
            let openedFile = ''
            if (this.$route.params.filePath && this.appType === this.appTypes.JUPYTER.name) {
                openedFile = '/lab/tree/' + this.$route.params.filePath
            }
            if (this.deployment.subpath) {
                openedFile = this.deployment.subpath
            }
            let query = ''
            if (this.appType === this.appTypes.JUPYTER.name) {
                if (this.$route.params.reset) query = '?reset&'
                const userName = this.userInfo.name || this.userInfo.given_name + ' ' + this.userInfo.family_name
                query = `username=${encodeURIComponent(userName.replace(' ', ''))}`
            }
            const url = 'https://' + this.deployment.domain + openedFile + (query ? `?${query}` : '')
            return url
        },
        wrapperStyle() {
            if (!this.isActive) {
                return { 'z-index': -1, opacity: 0, position: 'fixed', top: '0px', left: '65px', height: '100vh', width: 'calc(100vw-65px)' }
            }
            return {
                height: '100%',
                width: '100%',
                'overflow-x': 'hidden',
                'overflow-y': 'hidden'
            }
        },
        iframeStyle() {
            // handle hiDPI by scaling the iframe
            if (this.deployment.DPI !== '96') {
                return {
                    width: '200%',
                    height: '200vh',
                    border: '0',
                    transform: 'scale(0.5)',
                    'transform-origin': 'top left',
                    opacity: this.showLoader ? 0 : 1,
                    position: 'absolute',
                    'z-index': this.showLoader ? -1 : 'auto',
                    left: '0px'
                }
            }
            return {
                width: '100%',
                height: '100vh',
                border: '0',
                opacity: this.showLoader ? 0 : 1,
                position: 'absolute',
                'z-index': this.showLoader ? -1 : 'auto',
                left: '0px'
            }
        },
        isGuac() {
            return ['stata', 'spyder', 'octave', 'matlab', 'spss'].indexOf(this.appType) !== -1 || (this.appType && this.appType.toLowerCase().includes('guac'))
        },
        loadStatus() {
            if (this.deployment.available_replicas === 1) {
                return 3
            } else if (this.deployment.pod_status) {
                const podStatus = this.deployment.pod_status.filter(ps => ps.type === 'PodScheduled')
                if (podStatus.length && podStatus[0].status === 'True') {
                    return 2
                } else {
                    return 1
                }
            }
            return 1
        },
        showClipboardWarning() {
            return this.isGuac && !this.isChromiumBased && this.$route.name === 'app-open' && this.isActive && !this.showedClipboardWarnig
        },
        triggerFocus() {
            return this.$route.name === 'app-open' && this.isActive
        },
        resourceLimit() {
            const d = this.deployment
            if (d.limit_cpu !== 'N/A') {
                return { cpu: parseInt(d.limit_cpu.replace('m', '')) / 1000, mem: parseInt(d.limit_memory.replace('M', '')) / 1000 }
            }
            return { cpu: 0, mem: 0 }
        },
        ...mapState('orgStore', ['spacesWithPrimarySnapshots']),
        ...mapState(['userOrgs', 'userInfo']),
        ...mapGetters('instanceStore', ['isMasterInstance']),
        ...mapGetters('spaceStore', ['currentSpaceType', 'isSpaceAdmin', 'isTrialSpace'])
    },
    watch: {
        appType: {
            handler: function (to, from) {
                if (to === 'sas') {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Log in with username / password: nuvolos / cloud',
                        snackBarTimeout: 20000
                    })
                }
            }
        },
        showClipboardWarning: {
            handler: function (to, from) {
                if (to) {
                    this.showedClipboardWarnig = true
                    this.$store.dispatch('showGlobalDialog', {
                        dialogTitle: 'Change browser for clipboard support',
                        dialogText: 'Copy-Paste for this application is only fully supported on Chrome or Edge (Chromium) browsers.',
                        dialogAction: ['dismiss']
                    })
                }
            },
            immediate: true
        },
        triggerFocus: {
            handler: function (to) {
                if (to) {
                    this.focusIframe()
                }
            },
            immediate: true
        }
    }
}
</script>
